import { DatadogLoggingService } from '@edx/frontend-logging';
import IdVerificationPage from '@edx/frontend-plugin-persona';
import { PLUGIN_OPERATIONS, DIRECT_PLUGIN } from '@openedx/frontend-plugin-framework';
import NotificationsTray from '@edx/frontend-plugin-notifications';

const config = {
  loggingService: DatadogLoggingService,
  PERSONA_ENABLED: true,
  pluginSlots: {
    notifications_tray: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'notification_tray',
            type: DIRECT_PLUGIN,
            RenderWidget: NotificationsTray,
          },
        }
      ]
    }
  }
};

if (config.PERSONA_ENABLED) {
  config.pluginSlots.id_verification_page_plugin = {
    keepDefault: false,
    plugins: [
      {
        op: PLUGIN_OPERATIONS.Insert,
        widget: {
          id: 'id_verification_page_plugin',
          type: DIRECT_PLUGIN,
          RenderWidget: IdVerificationPage,
        },
      },
    ],
  }
}

export default config;
